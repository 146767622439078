const Icon = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = '9', height = '9', viewBox = '0 0 9 9', ...rest } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      {...rest}
      fill="none"
    >
      <path d="M4.05 0H4.95L9 2.7V9H8.1L4.5 7.2L0.9 9H0V2.7L4.05 0Z" fill="currentColor" />
    </svg>
  )
}

export default Icon
