import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { ConfirmModal, FlexLayout, PageSpinner } from 'components'
import { useValueContext } from 'contexts'

import Header from './Header'
import Form from './Form'
import Footer from './Footer'

import { isFormValid } from './utils'

import './styles.scss'

const Task = observer(() => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { taskStore } = useValueContext()

  const { task, loading, save, remove, isTaskChanged } = taskStore

  const isValid = isFormValid(task)

  return (
    <FlexLayout.Container vertical>
      <PageSpinner open={loading} />
      <Header />

      <FlexLayout.Content scrollable>
        <Form />
      </FlexLayout.Content>

      <Footer
        onSave={save}
        onDelete={() => setOpen(true)}
        isSaveDisabled={!isValid || !isTaskChanged}
      />

      <ConfirmModal open={open} onSetOpen={setOpen} onActionClick={remove}>
        {t('Вы уверены, что хотите удалить задачу?')}
      </ConfirmModal>
    </FlexLayout.Container>
  )
})

export default Task
