import { Nullable } from 'types'

export const isDef = <T>(value: Nullable<T>): value is T => {
  return value !== null && value !== undefined
}

export const isAllDef = (...values: any) => values.every(isDef)

export const isDefAndNotEmpty = <T extends ArrayLike<any>>(value: Nullable<T>): value is T => {
  if (isPrimitive(value)) return !!value
  return !!(value && value.length)
}

export const isNumeric = (n: string): boolean => !isNaN(parseFloat(n)) && isFinite(+n)

export const isPrimitive = (val: any): boolean =>
  !(typeof val == 'object' || typeof val == 'function')

export const parseFromStringToNumber = (value: string): number => {
  return parseFloat(value.replace(/,/g, '.'))
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  if (obj1 === null || obj2 === null) {
    return obj1 === obj2
  }

  if (Array.isArray(obj1) !== Array.isArray(obj2)) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}
