import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'
import Inputmask from 'inputmask'

import { FlexLayout, Calendar, PageHeader, Select } from 'components'
import { useTranslateConsts } from 'utils'

import './styles.scss'

export const Reminder = () => {
  const { t } = useTranslation()
  const { monthNames, dayNames } = useTranslateConsts()
  const navigate = useNavigate()
  const { projectId, taskId } = useParams()
  const [date, setDate] = useState(new Date('2022-12-10'))

  const onChange = (date: Date) => setDate(date)

  const ref = useCallback((node: HTMLInputElement) => {
    if (node) {
      Inputmask({ mask: '99:99' }).mask(node)
    }
  }, [])

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Напоминание')}</PageHeader>
      <FlexLayout.Content scrollable>
        <Calendar
          value={date}
          onChange={onChange}
          monthNames={monthNames}
          dayNames={dayNames}
          className="reminder-calendar"
        />
        <div className="task-input-wrapper select-option reminder-time">
          <span className="select-label">{t('Время')}</span>
          <Input ref={ref} placeholder="00:00" className="task-input" />
        </div>
        <Select
          label={t('Повторять')}
          value="Никогда"
          onClick={() => navigate(`/projects/${projectId}/tasks/${taskId}/settings/repeat`)}
        />
      </FlexLayout.Content>

      <div className="operations-buttons">
        <Button className="operation-button save">{t('Применить')}</Button>
        <Button className="operation-button delete">{t('Очистить')}</Button>
      </div>
    </FlexLayout.Container>
  )
}
