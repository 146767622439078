import { BaseSvgIcon } from './BaseSvgIcon'

export const DownloadCircleIcon = (props: any) => {
  const { width = 24, height = 24, fill = 'currentColor', ...rest } = props

  return (
    <BaseSvgIcon width={width} height={height} fill={fill} viewBox="0 0 24 24" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.1743 11.4444L14.5457 10.8159L12.476 12.8856V7H11.5871V12.8856L9.51744 10.8159L8.8889 11.4444L12.0316 14.5871L15.1743 11.4444ZM8 16.3333V15.4444H16V16.3333H8Z"
        fill={fill}
      />
    </BaseSvgIcon>
  )
}
