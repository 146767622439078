import React from 'react'
import { ButtonProps } from '@telegram-apps/telegram-ui'
import cn from 'classnames'

import { Button } from 'components'

import './styles.scss'

interface Props extends ButtonProps {
  children: React.ReactNode
  className?: string
}
export const OptionCard = (props: Props) => {
  const { children, className, ...rest } = props

  return (
    <Button className={cn('option-card', className)} {...rest}>
      {children}
    </Button>
  )
}
