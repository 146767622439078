import { apiService } from 'services'
import { TaskResponse, IEditedTask } from 'types'

import { PROJECTS_URL } from './ProjectApi'

const TASKS_URL = '/issues'

const getById = async (projectId: string, taskId: string) => {
  return apiService.get<TaskResponse>(`${PROJECTS_URL}/${projectId}${TASKS_URL}/${taskId}`)
}

const edit = async (projectId: string, taskId: string, data: IEditedTask) => {
  return apiService.put<TaskResponse>(`${PROJECTS_URL}/${projectId}${TASKS_URL}/${taskId}`, data)
}

const create = async (projectId: string, data: IEditedTask) => {
  return apiService.post<TaskResponse>(`${PROJECTS_URL}/${projectId}${TASKS_URL}`, data)
}

const remove = async (projectId: string, taskId: string) => {
  return apiService.delete<TaskResponse>(`${PROJECTS_URL}/${projectId}${TASKS_URL}/${taskId}`)
}

export const TaskApi = { getById, create, edit, remove }
