export const uniqBy = <T>(field: string, array: T[]): T[] => {
  const seen = new Set()
  const result = []

  for (const item of array) {
    const key = item[field as keyof T]
    if (!seen.has(key)) {
      seen.add(key)
      result.push(item)
    }
  }

  return result
}
