import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ConfirmModal, DeleteCircleIcon, Button } from 'components'

import './styles.scss'

interface Props {
  files: File[]
  onFileDelete: (index: number) => void
}
export const FilesList = ({ files, onFileDelete }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="files-list">
        {files.map((file, index) => {
          const url = URL.createObjectURL(file)

          return (
            <div key={index} className="file">
              <Button
                mode="plain"
                align="left"
                className="file-button"
                onClick={() =>
                  navigate(`settings/file`, {
                    state: {
                      url,
                      type: file.type,
                      name: file.name,
                    },
                  })
                }
              >
                {file.name}
              </Button>
              <DeleteCircleIcon className="file-delete" onClick={() => setOpen(true)} />
              <ConfirmModal
                open={open}
                onSetOpen={setOpen}
                onActionClick={() => onFileDelete(index)}
              >
                {t('Вы уверены, что хотите удалить файл')} <a href={url}>{file.name}</a>?
              </ConfirmModal>
            </div>
          )
        })}
      </div>
    </>
  )
}
