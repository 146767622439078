import { Checkbox } from '@telegram-apps/telegram-ui'

import { ScrollableList } from './ScrollableList'
import './styles.scss'

interface Props {
  options: Record<string, any>[]
  selected: string[]
  onSelect: (value: number) => void
  onClear: () => void
}

export const AuthorOptions = ({ options, selected, onSelect, onClear }: Props) => {
  return (
    <ScrollableList onClear={onClear}>
      {options?.map((it) => (
        <div className="author-label" onClick={() => onSelect(it.id)}>
          <div className="author-name">{it.name}</div>
          <Checkbox
            className="filter-list-checkbox"
            checked={selected.includes(it.id)}
            onChange={() => {}}
          />
        </div>
      ))}
    </ScrollableList>
  )
}
