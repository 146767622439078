import { useCallback } from 'react'

import type { IProjectItem } from 'types'

import IssueList from './IssueList'

interface ProjectItemProps {
  project: IProjectItem
  onClick?: (projectId: number) => void
}

const ProjectItem = ({ project, onClick }: ProjectItemProps) => {
  const handleClick = useCallback(() => {
    onClick?.(project.id)
  }, [project, onClick])

  return (
    <li className="project-item" onClick={handleClick}>
      <div className="title">{project.title}</div>
      <IssueList items={project.issueStatuses} />
    </li>
  )
}

export default ProjectItem
