import { useState, useEffect } from 'react'
import WebApp from '@twa-dev/sdk'

export const useTheme = () => {
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    const tgTheme = WebApp.colorScheme

    setTheme(tgTheme)
    document.body.className = `app-${tgTheme}`

    WebApp.onEvent('themeChanged', () => {
      const newTgTheme = WebApp.colorScheme
      setTheme(newTgTheme)
      document.body.className = `app-${newTgTheme}`
    })

    return () => {
      WebApp.offEvent('themeChanged', () => {})
    }
  }, [])

  return theme
}
