import { Outlet } from 'react-router-dom'
import { BackButton } from '@twa-dev/sdk/react'

import { FlexLayout } from 'components'

import './styles.scss'

export const ScrollableContentLayout = () => {
  return (
    <FlexLayout.Container vertical>
      <BackButton />
      <FlexLayout.Content className="scrollable-layout">
        <Outlet />
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
}
