import { Link, type To } from 'react-router-dom'

import PlusIcon from './plus.svg?react'

import './styles.scss'

interface CreateButtonProps {
  to: To
  children: React.ReactNode
}

const CreateButton = ({ to, children }: CreateButtonProps) => {
  return (
    <Link to={to} className="create-button">
      <PlusIcon /> {children}
    </Link>
  )
}

export default CreateButton
