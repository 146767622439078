import { ForwardedRef, forwardRef } from 'react'
import cn from 'classnames'

import './styles.scss'

interface FlexContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  vertical?: boolean
}

// Flex-контейнер (строка или колонка на всю высоту)
export const FlexContainer = ({ className, vertical = false, ...rest }: FlexContainerProps) => {
  return (
    <div
      className={cn('flex-container', { 'flex-container-column': vertical }, className)}
      {...rest}
    />
  )
}

interface FlexContentProps extends React.HTMLAttributes<HTMLDivElement> {
  scrollable?: boolean
}

// Контент flex-контейнера, который растягивается на всё доступное пространство контейнера
export const FlexContent = forwardRef(
  (
    { className, scrollable = false, ...rest }: FlexContentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        className={cn('flex-content', scrollable ? 'allow-scroll' : 'disallow-scroll', className)}
        {...rest}
        ref={ref}
      />
    )
  },
)

const FlexLayout = { Container: FlexContainer, Content: FlexContent }

export default FlexLayout
