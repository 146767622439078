import { Priority, Chip } from 'components'

import type { IProjectIssue } from 'types'

interface TaskInfoProps {
  number: number
  issue: IProjectIssue
}

const TaskInfo = ({ number, issue }: TaskInfoProps) => {
  return (
    <div className="task-info">
      <div className="flex-shrink-0"># {number}</div>
      {/* @ts-expect-error TODO - Сделать связку по типам для Задачи и Priority */}
      <Priority variant={issue.priority.key} />
      <Chip background={issue.status.colorHex}>{issue.status.title}</Chip>
      <div>{new Date(issue.modifiedAt || issue.createdAt).toLocaleDateString()}</div>
      <div className="executor">{issue.createdBy}</div>
    </div>
  )
}

export default TaskInfo
