import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PageHeader, Select } from 'components'

export const ProjectSettings = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <PageHeader> {t('Настройки')}</PageHeader>
      <Select
        value="UTC+3"
        label={t('Часовой пояс')}
        onClick={() => navigate(`/projects/${projectId}/settings/timezone`)}
      />
    </>
  )
}
