import React from 'react'
import WebApp from '@twa-dev/sdk'
import { Navigate, useLocation } from 'react-router-dom'

// @ts-expect-error потом раскомментируем нужные строки
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isDefAndNotEmpty } from 'utils'

interface Props {
  children?: React.ReactNode
}

export const ProtectedRoute = ({ children }: Props) => {
  // @ts-expect-error потом раскомментируем нужные строки
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tgKeys = Object.keys(WebApp.initDataUnsafe)
  const location = useLocation()

  // У роутов, на которые зашли по прямой ссылке, key === 'default'
  // @ts-expect-error потом раскомментируем нужные строки
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isFromPreviousRoute = location.key !== 'default'

  // TODO: включить проверку в финальной стадии разработки
  // const canAccess = isDefAndNotEmpty(tgKeys) && isFromPreviousRoute
  const canAccess = true

  if (!canAccess) {
    return <Navigate to="/" replace />
  }

  return children
}
