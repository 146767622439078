import { makeAutoObservable } from 'mobx'

import { pageSize } from 'consts'

interface IPagination {
  currentPage: number
  pageSize: number
  totalPages: number
  totalItems: number
}

interface PaginationStoreProps {
  onPageChange?: (page: number) => void
  onPageSizeChange?: (size: number) => void
}

export default class PaginationStore {
  currentPage: number
  pageSize: number
  totalPages: number
  totalItems: number

  onPageChange
  onPageSizeChange

  constructor({ onPageChange, onPageSizeChange }: PaginationStoreProps) {
    this.currentPage = 1
    this.pageSize = pageSize
    this.totalPages = 1
    this.totalItems = 0

    this.onPageChange = onPageChange
    this.onPageSizeChange = onPageSizeChange

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isFirstPage() {
    return this.currentPage === 1
  }

  get isLastPage() {
    return this.currentPage === this.totalPages
  }

  setCurrentPage(page: number) {
    const changed = page !== this.currentPage
    const valid = page >= 1 && page <= this.totalPages

    if (changed && valid) {
      this.currentPage = page
      this.pageChanged()
    }
  }

  setFirstPage() {
    if (!this.isFirstPage) {
      this.currentPage = 1
      this.pageChanged()
    }
  }

  setLastPage() {
    if (!this.isLastPage) {
      this.currentPage = this.totalPages
      this.pageChanged()
    }
  }

  setNextPage() {
    const nextPage = this.currentPage + 1

    if (nextPage <= this.totalPages && nextPage > this.currentPage) {
      this.setCurrentPage(nextPage)
      this.pageChanged()
    }
  }

  setPrevPage() {
    const prevPage = this.currentPage - 1

    if (prevPage >= 1 && prevPage < this.currentPage) {
      this.setCurrentPage(prevPage)
      this.pageChanged()
    }
  }

  setPageSize(rowsPerPage: number) {
    const changed = rowsPerPage !== this.pageSize
    const valid = rowsPerPage > 0

    if (changed && valid) {
      this.pageSize = rowsPerPage
      this.pageSizeChanged()

      this.setFirstPage()
    }
  }

  setPagination(pagination: IPagination) {
    if (pagination) {
      const { currentPage, pageSize, totalPages, totalItems } = pagination

      this.setCurrentPage(currentPage)
      this.setPageSize(pageSize)
      this.totalPages = totalPages
      this.totalItems = totalItems
    }
  }

  pageChanged() {
    if (typeof this.onPageChange === 'function') {
      this.onPageChange(this.currentPage)
    }
  }

  pageSizeChanged() {
    if (typeof this.onPageSizeChange === 'function') {
      this.onPageSizeChange(this.pageSize)
    }
  }
}
