import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import WebApp from '@twa-dev/sdk'

import { useTheme } from 'utils/hooks'

import './styles.scss'
import { useEffect } from 'react'

const AppLayout = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  useEffect(() => {
    const startApp = WebApp.initDataUnsafe.start_param || ''
    const match = startApp.match(/projects_(\d+)/)

    if (match) {
      const projectId = match[1]
      navigate(`/projects/${projectId}`, { replace: true })
    }
  }, [navigate])

  useTheme()

  // TODO: удалить кнопку и функцию после теста и добавления всех значений
  const onChangeLang = () => {
    let lang = ''

    switch (i18n.language) {
      case 'en':
        lang = 'es'
        break
      case 'es':
        lang = 'ru'
        break
      case 'ru':
        lang = 'en'
    }

    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    i18n.changeLanguage(WebApp?.initDataUnsafe?.user?.language_code || 'en')
  }, [i18n])

  return (
    <div className="full-screen">
      <button style={{ position: 'fixed', top: 0 }} onClick={onChangeLang}>
        {i18n.language} Сменить язык
      </button>
      <Outlet />
    </div>
  )
}

export default AppLayout
