import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'

import { ProjectApi } from 'api'
import { PaginationStore, SortingStore } from 'stores/shared'
import { isDef, isDefAndNotEmpty, uniqBy } from 'utils'
import {
  BasicGetParams,
  IProject,
  IProjectConfig,
  IProjectIssue,
  ITaskPerson,
  ProjectConfigResponse,
  ProjectIssuesResponse,
  ProjectResponse,
  ProjectUsersResponse,
  SelectedFilters,
} from 'types'

const initialSelectedFilters = {
  assignees: [],
  statuses: [],
  reporters: [],
}

export default class ProjectStore {
  project: IProject
  issues: IProjectIssue[] = []
  selectedFilters: SelectedFilters = initialSelectedFilters
  loading = false
  loadingMore = false
  search = ''
  projectId
  sorting
  pagination

  // Справочные значения
  config: IProjectConfig = {
    statuses: [],
    types: [],
    priorities: [],
  }
  users: ITaskPerson[] = []

  constructor(projectId: string) {
    // TODO: Инициализация пустого проекта?
    // @ts-expect-error Сделать функцию инициализации пустого проекта на основе IProject
    this.project = {}
    this.projectId = Number(projectId)

    this.sorting = new SortingStore()
    this.pagination = new PaginationStore({})

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isEmptyConfig() {
    return Object.keys(this.config).every(
      (it) => !isDefAndNotEmpty(this.config[it as keyof IProjectConfig]),
    )
  }

  get isEmptyUsers() {
    return !isDefAndNotEmpty(this.users)
  }

  setSearch = (value: string) => {
    this.search = value
  }

  resetFilters = () => {
    this.selectedFilters = initialSelectedFilters
    this.sorting.resetSort()
  }

  setArrayFilter = (field: keyof SelectedFilters, value: string | number) => {
    if (!Object.prototype.hasOwnProperty.call(this.selectedFilters, field)) {
      return
    }

    const selectedValue = this.selectedFilters[field].find((it) => it === value)

    if (!isDef(selectedValue)) {
      this.selectedFilters[field] = [...this.selectedFilters[field], value]
    } else {
      this.selectedFilters[field] = this.selectedFilters[field].filter((it) => it !== value)
    }
  }

  clearArrayFilter = (field: keyof SelectedFilters) => {
    if (!Object.prototype.hasOwnProperty.call(this.selectedFilters, field)) {
      return
    }
    this.selectedFilters[field] = []
  }

  getListParams = (): BasicGetParams => {
    return {
      Page: this.pagination.currentPage,
      Size: this.pagination.pageSize,
      Sorts: this.sorting.getSort(),
      Title: isDefAndNotEmpty(this.search) ? this.search : undefined,
      ...this.selectedFilters,
    }
  };

  *initDictsIfEmpty() {
    const promises = []

    if (this.isEmptyConfig) {
      promises.push(this.loadConfig(this.projectId))
    }
    if (this.isEmptyUsers) {
      promises.push(this.loadUsers(this.projectId))
    }

    yield Promise.allSettled(promises)
  }

  *loadById(projectId: number) {
    if (Number.isFinite(projectId)) {
      this.loading = true

      try {
        const response: AxiosResponse<ProjectResponse> = yield ProjectApi.getById(projectId)
        const loadedProject = response.data.data

        this.project = loadedProject
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  *loadIssues(projectId: number) {
    if (Number.isFinite(projectId)) {
      this.loading = true

      try {
        const response: AxiosResponse<ProjectIssuesResponse> = yield ProjectApi.getIssuesById(
          projectId,
          this.getListParams(),
        )

        const { data, currentPage, totalPages } = response.data

        this.issues = data

        this.pagination.setCurrentPage(currentPage)
        this.pagination.totalPages = totalPages
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  *loadMoreIssues() {
    const isEndOfData = this.pagination.currentPage > this.pagination.totalPages

    if (Number.isFinite(this.projectId) && !isEndOfData && !this.loadingMore) {
      this.loadingMore = true

      try {
        const response: AxiosResponse<ProjectIssuesResponse> = yield ProjectApi.getIssuesById(
          this.projectId,
          this.getListParams(),
        )

        const { data, currentPage } = response.data

        this.issues = uniqBy('id', [...this.issues, ...data])
        this.pagination.setCurrentPage(currentPage)
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loadingMore = false
      }
    }
  }

  *loadConfig(projectId: number) {
    if (Number.isFinite(projectId)) {
      this.loading = true

      try {
        const response: AxiosResponse<ProjectConfigResponse> =
          yield ProjectApi.getConfigById(projectId)

        this.config = response.data.data
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  *loadUsers(projectId: number) {
    if (Number.isFinite(projectId)) {
      this.loading = true

      try {
        const response: AxiosResponse<ProjectUsersResponse> =
          yield ProjectApi.getUsersById(projectId)

        this.users = response.data.data
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }
}
