import axios from 'axios'
import qs from 'qs'

const apiService = axios.create({
  baseURL: '/api',
  paramsSerializer: { serialize: (params) => qs.stringify(params) },
})

export const setAuthHeader = (token: string) => {
  if (token) apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default apiService
