export const getFullName = (name?: string, surname?: string) => {
  return [name, surname].filter(Boolean).join(' ')
}

export const formatBalance = (num: number) => {
  const value = Number.isFinite(num) ? num : 0
  const formatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: 1 })

  return formatter.format(value)
}
