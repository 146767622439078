const priorities: any = [
  {
    id: 1,
    key: 'high',
    title: 'Высокий',
  },
  {
    id: 2,
    key: 'middle',
    title: 'Средний',
  },
  {
    id: 3,
    key: 'low',
    title: 'Низкий',
  },
]

export default priorities
