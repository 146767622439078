import { Spinner } from '../UI'
import './styles.scss'

const PageSpinner = ({ open = false }: { open: boolean }) =>
  open ? (
    <div className="page-spinner">
      <Spinner open={open} />
    </div>
  ) : null

export default PageSpinner
