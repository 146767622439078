import { useTranslation } from 'react-i18next'
import { dayNames, monthNames, repeatTimeItems, statuses, priorities } from 'consts'
import { useMemo } from 'react'

export const useTranslateConsts = () => {
  const { t } = useTranslation()

  const translatedStatuses = useMemo(
    () =>
      statuses.map((it: Record<string, any>) => ({
        ...it,
        title: t(it.title),
      })),
    [t],
  )

  const translatedPriorities = useMemo(
    () =>
      priorities.map((it: Record<string, any>) => ({
        ...it,
        title: t(it.title),
      })),
    [t],
  )

  const translatedRepeatTimeItems = useMemo(
    () =>
      repeatTimeItems.map((it: Record<string, any>) => ({
        ...it,
        title: t(it.title),
      })),
    [t],
  )

  const translatedDayNames = useMemo(() => dayNames.map((it: string) => t(it)), [t])
  const translatedMonthNames = useMemo(() => monthNames.map((it: string) => t(it)), [t])

  return {
    statuses: translatedStatuses,
    dayNames: translatedDayNames,
    monthNames: translatedMonthNames,
    repeatTimeItems: translatedRepeatTimeItems,
    priorities: translatedPriorities,
  }
}
