import React from 'react'

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  fill?: string
}

export const BaseSvgIcon = (props: SvgIconProps) => {
  const {
    viewBox = '0 0 24 24',
    width = 24,
    height = 24,
    fill = 'currentColor',
    children,
    ...restProps
  } = props

  return (
    <svg
      style={{ flexShrink: 0 }}
      viewBox={viewBox}
      width={width}
      height={height}
      focusable={false}
      fill={fill}
      {...restProps}
    >
      {children}
    </svg>
  )
}
