import { BaseSvgIcon } from './BaseSvgIcon'

export const DeleteCircleIcon = (props: any) => {
  const { width = 20, height = 20, fill = 'var(--error)', ...rest } = props

  return (
    <BaseSvgIcon width={width} height={height} fill={fill} viewBox="0 0 32 32" {...rest}>
      <path
        d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M19.5,18.1c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.5,0.3-0.7,0.3
	s-0.5-0.1-0.7-0.3L16,17.4l-2.1,2.1c-0.2,0.2-0.5,0.3-0.7,0.3s-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l2.1-2.1l-2.1-2.1
	c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.1,2.1l2.1-2.1c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L17.4,16L19.5,18.1z"
      />
    </BaseSvgIcon>
  )
}
