import cn from 'classnames'

import './styles.scss'

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: number
}

const Avatar = ({ className, size, ...rest }: AvatarProps) => {
  return <img {...rest} width={size} height={size} className={cn('avatar', className)} />
}

export default Avatar
