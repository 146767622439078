import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'
import { useValueContext } from 'contexts'

interface FooterProps {
  isSaveDisabled: boolean
  onSave: () => void
  onDelete: () => void
}

const Footer = observer((props: FooterProps) => {
  const { isSaveDisabled = false, onSave, onDelete } = props

  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { isNewTask } = taskStore

  return (
    <footer className="operations-buttons">
      <Button className="operation-button save" onClick={onSave} disabled={isSaveDisabled}>
        {t('Сохранить изменения')}
      </Button>
      {!isNewTask && (
        <Button className="operation-button delete" onClick={onDelete}>
          {t('Удалить задачу')}
        </Button>
      )}
    </footer>
  )
})

export default Footer
