import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Input } from '@telegram-apps/telegram-ui'

import './styles.scss'
import { CloseCircleIcon, SearchIcon } from '../UI'

interface Props {
  value: string
  onChange: (value: string) => void
  onSearch: () => void
  className?: string
}

export const SearchInput = (props: Props) => {
  const { value, onChange, onSearch, className } = props
  const { t } = useTranslation()

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const onReset = () => {
    onChange('')
    onSearch()
  }

  return (
    <div className={cn('search-input-wrapper', className)}>
      <Input
        className="search-input"
        placeholder={`${t('Поиск')}...`}
        before={<SearchIcon />}
        after={value && <CloseCircleIcon className="search-clear-icon" onClick={onReset} />}
        value={value}
        onKeyPress={onKeyPress}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
