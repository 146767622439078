import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { FlexLayout, PageHeader } from 'components'

import './styles.scss'
import { timeZones } from './mock'

export const TimeZone = () => {
  const { t } = useTranslation()
  const onSelect = console.log
  const selected = 'UTC-9'

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Часовой пояс')}</PageHeader>
      <FlexLayout.Content scrollable>
        {timeZones.map((it) => (
          <div
            key={it}
            className={cn('time-zone', { selected: selected === it })}
            onClick={() => onSelect(it)}
          >
            {it}
          </div>
        ))}
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
}
