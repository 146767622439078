import cn from 'classnames'

import React from 'react'

import { PageHeader } from 'components'

import './styles.scss'

interface Props {
  children: React.ReactNode
  after?: React.ReactNode
  className?: string
}

export const HeaderWithControl = (props: Props) => {
  const { children, after, className } = props
  return (
    <div className={cn('container-header-controls', className)}>
      <PageHeader className={cn({ 'pe-2': after })} style={{ width: '100%' }}>
        {children}
      </PageHeader>
      {after}
    </div>
  )
}
