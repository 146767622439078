import React from 'react'
import cn from 'classnames'

import Icon from './no-data.svg?react'

import './styles.scss'

interface NoDataProps {
  children?: React.ReactNode
  className?: string
}

const NoData = ({ children, className }: NoDataProps) => (
  <div className={cn('no-data', className)}>
    <Icon />
    {children && <div className="description">{children}</div>}
  </div>
)

export default NoData
