import cn from 'classnames'

import type { ChipProps } from './types'

import './styles.scss'
import { CSSProperties } from 'react'

const Chip = ({ className, background, ...rest }: ChipProps) => {
  return (
    <span
      {...rest}
      className={cn('chip', className)}
      style={{ '--chip-background': background } as CSSProperties}
    />
  )
}

export default Chip
