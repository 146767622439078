import React from 'react'
import throttle from 'lodash.throttle'

export const useInfiniteScroll = (
  loading: boolean,
  onNextPageByScroll: () => void,
): ((e: React.UIEvent<HTMLElement>) => void) => {
  return throttle(
    (e: React.UIEvent<HTMLElement>) => {
      const target: HTMLElement = e.target as HTMLElement

      // Если прокрутили до определенного процента списка (0.5 = 50% списка)
      const isScrolledToThreshold =
        target.scrollTop >= (target.scrollHeight - target.offsetHeight) * 0.5

      const shouldLoadMoreData = isScrolledToThreshold && !loading

      if (shouldLoadMoreData) {
        onNextPageByScroll?.()
      }
    },
    1000,
    {
      leading: false,
      trailing: true,
    },
  )
}
