import cn from 'classnames'

import type { PriorityVariant, PriorityProps } from './types'
import Icon from './Icon'

import './styles.scss'

const variants: Record<PriorityVariant, string> = {
  low: 'priority-low',
  medium: 'priority-medium',
  high: 'priority-high',
}

const Priority = ({ className, variant, ...rest }: PriorityProps) => {
  return <Icon className={cn('priority', variants[variant], className)} {...rest} />
}

export default Priority
