import { useTranslation } from 'react-i18next'

import { BottomFooter, CreateButton } from 'components'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <BottomFooter>
      <CreateButton to="https://t.me/SquadTest1Bot?startgroup=true">
        {t('Создать проект')}
      </CreateButton>
    </BottomFooter>
  )
}

export default Footer
