import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { PageHeader } from 'components'
import { useValueContext } from 'contexts'

const Header = observer(() => {
  const { t } = useTranslation()
  const { taskStore } = useValueContext()
  const { isNewTask } = taskStore

  const title = isNewTask ? 'Новая задача' : 'Редактирование задачи'

  return <PageHeader>{t(title)}</PageHeader>
})

export default Header
