import type { WebAppUser } from '@twa-dev/types'

import { apiService } from 'services'

import { TokenResponse } from 'types'

const getToken = async (data: { user: WebAppUser; hash: string }) => {
  return apiService.post<TokenResponse>('/auth', data)
}

const AuthApi = { getToken }

export default AuthApi
