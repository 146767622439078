import './styles.scss'

interface Props {
  data: Record<string, any>
  className?: string
}
export const AssigneeLabel = ({ data, className }: Props) => {
  return (
    <div className={className}>
      <div className="assignee-name">{data.name}</div>
      {data.login && <div className="assignee-login">@{data.login}</div>}
    </div>
  )
}
