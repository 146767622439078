import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'

import { PageHeader, OptionCard, Priority as PriorityIcon } from 'components'
import { useValueContext } from 'contexts'
import { DictValue } from 'types'

import './styles.scss'

export const Priority = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { taskStore, projectStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const { config } = projectStore

  const onSelect = (priority: DictValue) => {
    setTaskField('priority', priority)
    navigate(-1)
  }

  return (
    <>
      <PageHeader>{t('Приоритет')}</PageHeader>

      {config?.priorities?.map((x: any) => {
        return (
          <OptionCard
            key={x.id}
            className={cn('priority-option', { selected: x.id === task?.priority?.id })}
            before={<PriorityIcon width={14} height={14} variant={x.key} />}
            onClick={() => onSelect(x)}
          >
            {t(x.title)}
          </OptionCard>
        )
      })}
    </>
  )
})
