import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'
import { NavigateFunction } from 'react-router'

import { TaskApi } from 'api'
import { IEditedTask, IProjectConfig, ITask, ITaskPerson, Nullable, TaskResponse } from 'types'

import { createEmptyTask, serializeTask } from './utils'
import { deepEqual } from 'utils'

export default class TaskStore {
  projectId: string
  taskId: string
  navigate: NavigateFunction
  task: Nullable<ITask>
  originalTask: Nullable<ITask>
  loading: boolean = false

  constructor(projectId: string, taskId: string, navigate: NavigateFunction) {
    this.projectId = projectId
    this.taskId = taskId
    this.navigate = navigate

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isNewTask() {
    return this.taskId === 'new'
  }

  get isTaskChanged() {
    return !deepEqual(this.task, this.originalTask)
  }

  initEmptyTask(config: IProjectConfig, users: ITaskPerson[]) {
    // Инициализируем выпадающие списки первым значением из списка
    const { types, statuses, priorities } = config

    const type = types[0]
    const status = statuses[0]
    const priority = priorities[0]
    const owner = users[0]

    const emptyTask = createEmptyTask({ type, status, priority, owner }) as ITask

    this.task = emptyTask
    this.originalTask = emptyTask
  }

  *init(config: IProjectConfig, users: ITaskPerson[]) {
    if (this.isNewTask) {
      this.initEmptyTask(config, users)
    } else {
      yield this.loadById()
    }
  }

  *loadById() {
    if (this.taskId) {
      this.loading = true

      try {
        const response: AxiosResponse<TaskResponse> = yield TaskApi.getById(
          this.projectId,
          this.taskId,
        )
        const data = response.data.data

        this.task = data
        this.originalTask = data
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  *save() {
    if (this.projectId) {
      this.loading = true

      try {
        const serialized = serializeTask(this.task)

        if (this.isNewTask) {
          const { data } = yield TaskApi.create(this.projectId, serialized)

          const newId = data.data
          this.loading = false
          this.taskId = newId
          const updatedUrl = window.location.pathname.replace('new', newId)
          window.history.replaceState(null, '', updatedUrl)
          yield this.loadById()
        } else {
          yield TaskApi.edit(this.projectId, this.taskId, serialized)
          this.navigate(`/projects/${this.projectId}`, { replace: true })
          this.navigate(-1)
        }
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  *remove() {
    if (this.taskId) {
      this.loading = true

      try {
        yield TaskApi.remove(this.projectId, this.taskId)

        if (this.projectId) {
          this.navigate(`/projects/${this.projectId}`, { replace: true })
          this.navigate(-1)
        }
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }

  // Ввод значения с формы
  setTaskField = (field: keyof IEditedTask, value: any) => {
    if (this.task && Object.prototype.hasOwnProperty.call(this.task, field)) {
      this.task = {
        ...this.task,
        [field]: value,
      }
    }
  }
}
