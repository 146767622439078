import React, { useState } from 'react'
import { FileInput } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'

import { isDefAndNotEmpty } from 'utils'

import { FilesList } from '../FilesList'
import './styles.scss'

export const AttachedFiles = () => {
  const { t } = useTranslation()
  const [files, setFiles] = useState<File[]>([])

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files as FileList)
    setFiles(selectedFiles)
  }

  const onFileDelete = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index)
    setFiles(newFiles)
  }

  return (
    <div className="attached-files">
      {isDefAndNotEmpty(files) && <FilesList files={files} onFileDelete={onFileDelete} />}
      <FileInput
        className="attach-file-input"
        label={t('Прикрепить файл')}
        onChange={onFilesChange}
        multiple
      />
    </div>
  )
}
