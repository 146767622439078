export interface DictValue {
  id?: number
  key?: string
  title: string
  colorHex: string
  iconUrl: string
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
