import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import HomeIcon from './home.svg?react'
import FolderIcon from './folder.svg?react'

const NavBar = () => {
  const { t } = useTranslation()

  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li className="nav-item">
          <NavLink to="/" className="nav-link">
            <HomeIcon />
            {t('Главная')}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/projects" className="nav-link">
            <FolderIcon />
            {t('Проекты')}
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
