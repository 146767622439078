import { useState } from 'react'

import { AppStore } from 'stores'

import AppContext from './AppContext'

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [app] = useState(() => new AppStore())

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>
}

export default AppProvider
