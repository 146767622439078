import React from 'react'

import { isDef } from 'utils'

import { ValueContext } from './ValueContext'

export const useValueContext = () => {
  const context = React.useContext(ValueContext)

  if (!isDef(context)) {
    throw new Error('useValueContext must be used within ValueContext.Provider')
  }

  return context
}
