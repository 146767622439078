type RepeatItem = {
  id: string
  title: string
}

export const repeatTimeItems: RepeatItem[] = [
  { id: 'never', title: 'Никогда' },
  { id: 'daily', title: 'Ежедневно' },
  { id: 'selectedDays', title: 'Выбранные дни' },
  { id: 'weekly', title: 'Еженедельно' },
  { id: 'monthly', title: 'Ежемесячно' },
  { id: 'yearly', title: 'Ежегодно' },
]
