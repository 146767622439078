import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components'

import './styles.scss'

interface Props {
  children: ReactNode
  onClear: () => void
}

export const ScrollableList = ({ children, onClear }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="filter-scrollable-list">{children}</div>
      <Button mode="plain" align="left" className="clear-filter-btn" onClick={onClear}>
        {t('Очистить фильтр')}
      </Button>
    </>
  )
}
