import { useTranslation } from 'react-i18next'
import { Badge } from '@telegram-apps/telegram-ui'

import { Select } from 'components'

import './styles.scss'

interface Props {
  color: string
  value: string
  onClick: () => void
}

export const StatusSelect = (props: Props) => {
  const { color, value, onClick } = props
  const { t } = useTranslation()

  return (
    <Select
      className="status-select"
      label={`${t('Статус')} *`}
      value={value}
      before={value && <Badge type="number" style={{ backgroundColor: color }} />}
      onClick={onClick}
    />
  )
}
