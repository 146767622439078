import { makeAutoObservable } from 'mobx'
import { AxiosResponse } from 'axios'
import type { WebAppUser } from '@twa-dev/types'

import { AuthApi } from 'api'
import { setAuthHeader } from 'services/apiService'
import { TokenResponse } from 'types'

export default class AuthStore {
  token?: string
  initialized: boolean
  loading: boolean

  constructor() {
    this.initialized = false
    this.loading = false

    makeAutoObservable(this)
  }

  *loadToken(user: WebAppUser | undefined, hash: string) {
    if (user && hash) {
      this.initialized = false
      this.loading = true

      try {
        const response: AxiosResponse<TokenResponse> = yield AuthApi.getToken({ user, hash })
        const loadedToken = response.data.data.token

        this.token = loadedToken
        setAuthHeader(loadedToken)

        this.initialized = true
      } catch (ex: any) {
        console.log(ex?.message)
      } finally {
        this.loading = false
      }
    }
  }
}
