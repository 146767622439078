const statuses: any = [
  {
    id: 1,
    key: 'new',
    title: 'Новая',
  },
  {
    id: 2,
    key: 'progress',
    title: 'В работе',
  },
  {
    id: 3,
    key: 'test',
    title: 'Тестирование',
  },
  {
    id: 4,
    key: 'done',
    title: 'Закрыта',
  },
  {
    id: 5,
    key: 'hold',
    title: 'Отложена',
  },
]

export default statuses
