import { makeAutoObservable } from 'mobx'
import WebApp from '@twa-dev/sdk'
import type { WebAppInitData } from '@twa-dev/types'

import AuthStore from './AuthStore'

export default class AppStore {
  initData: WebAppInitData
  auth

  constructor() {
    this.initData = WebApp.initDataUnsafe
    this.auth = new AuthStore()

    this.init()

    makeAutoObservable(this)
  }

  get initialized() {
    return this.auth.initialized
  }

  init() {
    this.auth.loadToken(this.initData.user, this.initData.hash)
  }
}
