import { AllHTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'

import './styles.scss'

// Код взят и переработан под нужды проекта отсюда:
// https://github.com/Telegram-Mini-Apps/TelegramUI/blob/main/src/components/Blocks/Button/Button.tsx

export interface ButtonProps extends Omit<AllHTMLAttributes<HTMLButtonElement>, 'size'> {
  before?: ReactNode
  after?: ReactNode
  size?: 's' | 'm' | 'l'
  align?: 'left' | 'center' | 'right' | 'between'
  mode?: 'filled' | 'bezeled' | 'plain' | 'gray' | 'outline' | 'white'
  stretched?: boolean
  disabled?: boolean
}

const modeStyles = {
  filled: 'button-wrapper--filled',
  bezeled: 'button-wrapper--bezeled',
  plain: 'button-wrapper--plain',
  gray: 'button-wrapper--gray',
  outline: 'button-wrapper--outline',
  white: 'button-wrapper--white',
}

const sizeStyles = {
  s: 'button-wrapper--s',
  m: 'button-wrapper--m',
  l: 'button-wrapper--l',
}

const alignStyles = {
  left: 'button-wrapper--left',
  center: 'button-wrapper--center',
  right: 'button-wrapper--right',
  between: 'button-wrapper--between',
}

export const Button = (props: ButtonProps) => {
  const {
    size = 'm',
    align,
    before,
    after,
    stretched,
    children,
    className,
    mode = 'filled',
    ...rest
  } = props

  return (
    <button
      className={cn(
        'button-wrapper',
        mode && modeStyles[mode],
        size && sizeStyles[size],
        align && alignStyles[align],
        stretched && 'button-wrapper--stretched',
        className,
      )}
      {...rest}
      type="button"
    >
      {before && <div className="before">{before}</div>}
      <div className="content">{children}</div>
      {after && <div className="after">{after}</div>}
    </button>
  )
}
