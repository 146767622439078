import { Checkbox } from '@telegram-apps/telegram-ui'

interface Props {
  days: string[]
}

export const WeekDays = ({ days }: Props) => {
  return (
    <div className="week-days">
      {days.map((day, index) => (
        <div key={index}>
          <div style={{ marginBottom: '10px' }}>{day}</div>
          <Checkbox checked={false} />
        </div>
      ))}
    </div>
  )
}
