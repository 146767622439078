import './styles.scss'

interface Props {
  label: string
}

export const RequiredInputLabel = ({ label }: Props) => {
  return (
    <>
      {label} <span className="required-asterisk">*</span>
    </>
  )
}
