import { CSSProperties } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FlexLayout, PageHeader, OptionCard } from 'components'
import { useValueContext } from 'contexts'
import { DictValue } from 'types'

import './styles.scss'

export const Status = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { taskStore, projectStore } = useValueContext()
  const { config } = projectStore
  const { task, setTaskField } = taskStore

  const onSelect = (status: DictValue) => {
    setTaskField('status', status)
    navigate(-1)
  }

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Статус')}</PageHeader>

      <FlexLayout.Content scrollable>
        {config?.statuses?.map((x: DictValue) => {
          return (
            <OptionCard
              key={x.id}
              className={cn('status-option', { selected: x.id === task?.status?.id })}
              style={{ '--status-background': x.colorHex } as CSSProperties}
              onClick={() => onSelect(x)}
            >
              {t(x.title)}
            </OptionCard>
          )
        })}
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
})
