import { Checkbox } from '@telegram-apps/telegram-ui'

import { AssigneeLabel } from 'components'

import { ScrollableList } from './ScrollableList'
import './styles.scss'

interface Props {
  options: Record<string, any>[]
  selected: number[]
  onSelect: (value: number) => void
  onClear: () => void
}

export const AssigneeOptions = ({ options, selected, onSelect, onClear }: Props) => {
  return (
    <ScrollableList onClear={onClear}>
      {options?.map((it) => {
        return (
          <div key={it.id} className="assignee-label" onClick={() => onSelect(it.id)}>
            <AssigneeLabel data={it} />
            <Checkbox
              className="filter-list-checkbox"
              checked={selected.includes(it.id)}
              onChange={() => {}}
            />
          </div>
        )
      })}
    </ScrollableList>
  )
}
