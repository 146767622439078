import { makeAutoObservable } from 'mobx'
import { WebAppUser } from '@twa-dev/types'

import { getFullName, formatBalance } from './utils'

export default class UserStore {
  firstName: string
  lastName?: string
  avatar?: string

  balance: number

  constructor(tgUser?: WebAppUser) {
    // Если есть tgUser - приложение запущено в Telegram, иначе в браузере
    if (tgUser) {
      this.firstName = tgUser.first_name
      this.lastName = tgUser.last_name
      this.avatar = tgUser.photo_url
    } else {
      this.firstName = 'Unknown User'
    }

    this.balance = 0

    makeAutoObservable(this)
  }

  get fullName() {
    return getFullName(this.firstName, this.lastName)
  }

  get formattedBalance() {
    return formatBalance(this.balance)
  }
}
