import React, { useState } from 'react'
import cn from 'classnames'
import { Modal, Placeholder } from '@telegram-apps/telegram-ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { NString, SortDirection } from 'types'
import { useValueContext } from 'contexts'
import { ArrowIcon, SortIcon, Button } from 'components'
import { isDefAndNotEmpty } from 'utils'

import { AssigneeOptions, AuthorOptions, SortOptions, StatusOptions } from './components'
import './styles.scss'

export const TaskFilters = observer(() => {
  const { t } = useTranslation()

  const [currentModal, setCurrentModal] = useState<NString>(null)
  const [open, setOpen] = useState(false)
  const {
    users,
    config,
    selectedFilters,
    sorting,
    setArrayFilter,
    resetFilters,
    clearArrayFilter,
  } = useValueContext()
  const { statuses } = config

  const onSelectSorts = (sortKey: string, sortDirection: SortDirection) => {
    setOpen(false)
    sorting.setSort({ key: sortKey, direction: sortDirection })
  }

  const fieldsMap: Record<string, React.ReactNode> = {
    sort: (
      <SortOptions
        selected={{ key: sorting.key, direction: sorting.direction }}
        onSelect={(sortKey, sortDirection) => onSelectSorts(sortKey, sortDirection)}
        onClear={() => onSelectSorts('', SortDirection.DESC)}
      />
    ),
    assignee: (
      <AssigneeOptions
        options={users}
        selected={selectedFilters.assignees}
        onSelect={(id) => setArrayFilter('assignees', id)}
        onClear={() => clearArrayFilter('assignees')}
      />
    ),
    status: (
      <StatusOptions
        options={statuses}
        selected={selectedFilters.statuses}
        onSelect={(id) => setArrayFilter('statuses', id)}
        onClear={() => clearArrayFilter('statuses')}
      />
    ),
    author: (
      <AuthorOptions
        options={users}
        selected={selectedFilters.reporters}
        onSelect={(id) => setArrayFilter('reporters', id)}
        onClear={() => clearArrayFilter('reporters')}
      />
    ),
  }

  const onOpenModal = (currentState: string) => {
    setCurrentModal(currentState)
    setOpen(true)
  }

  return (
    <div className="tasks-filters-wrapper">
      <Modal
        open={open}
        onOpenChange={(state) => setOpen(state)}
        className="modal-rounded"
        dismissible
      >
        <Placeholder>{fieldsMap[currentModal as string]}</Placeholder>
      </Modal>
      <div className="tasks-filters">
        <Button
          mode="outline"
          onClick={() => onOpenModal('sort')}
          className={cn('filter-button', { selected: isDefAndNotEmpty(sorting.key) })}
          before={<SortIcon />}
        >
          {t('Сортировка')}
        </Button>
        <Button
          mode="outline"
          onClick={() => onOpenModal('assignee')}
          className={cn('filter-button', { selected: isDefAndNotEmpty(selectedFilters.assignees) })}
          after={<ArrowIcon className="filter-dropdown-icon" />}
        >
          {t('Исполнитель')}
        </Button>
        <Button
          mode="outline"
          onClick={() => onOpenModal('status')}
          className={cn('filter-button', { selected: isDefAndNotEmpty(selectedFilters.statuses) })}
          after={<ArrowIcon className="filter-dropdown-icon" />}
        >
          {t('Статус')}
        </Button>
        <Button
          mode="outline"
          onClick={() => onOpenModal('author')}
          className={cn('filter-button', { selected: isDefAndNotEmpty(selectedFilters.reporters) })}
          after={<ArrowIcon className="filter-dropdown-icon" />}
        >
          {t('Автор')}
        </Button>
        <Button mode="plain" className="filter-button reset-filters-button" onClick={resetFilters}>
          {t('Очистить фильтры')}
        </Button>
      </div>
    </div>
  )
})
