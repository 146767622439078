import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button } from 'components'
import { SortDirection } from 'types'

import './styles.scss'

const options = [
  {
    label: 'Дата создания',
    value: 'сначала новые',
    key: 'CreatedAt',
    direction: SortDirection.DESC,
  },
  {
    label: 'Дата создания',
    value: 'сначала старые',
    key: 'CreatedAt',
    direction: SortDirection.ASC,
  },
  {
    label: 'Дата изменения',
    value: 'сначала новые',
    key: 'ModifiedAt',
    direction: SortDirection.DESC,
  },
  {
    label: 'Дата изменения',
    value: 'сначала старые',
    key: 'ModifiedAt',
    direction: SortDirection.ASC,
  },
]

interface Props {
  selected: {
    direction: SortDirection
    key: string
  }
  onSelect: (value: string, direction: SortDirection) => void
  onClear: () => void
}

export const SortOptions = ({ selected, onSelect, onClear }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="sort-options">
      {options.map(({ label, key, direction, value }) => {
        const isSelected = selected.key === key && selected.direction === direction
        return (
          <Button
            mode="plain"
            align="left"
            className={cn('sort-option', { selected: isSelected })}
            onClick={() => onSelect(key, direction)}
            key={`${key}${direction}`}
          >
            <span className="label">{t(label)}</span>: {t(value)}
          </Button>
        )
      })}
      <Button align="left" mode="plain" className="clear-filter-btn" onClick={onClear}>
        {t('Очистить сортировку')}
      </Button>
    </div>
  )
}
