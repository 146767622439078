import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { Input } from '@telegram-apps/telegram-ui'

import { ProjectApi } from 'api'
import { FlexLayout, PageHeader, Button, RequiredInputLabel } from 'components'
import type { ProjectResponse, INewProject } from 'types'

import './styles.scss'

const NewProject = () => {
  const [newProject, setNewProject] = useState<INewProject>({
    title: '',
    description: '',
  })
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const hasTitle = newProject.title.trim().length

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewProject((curr) => ({ ...curr, [e.target.name]: e.target.value }))
  }

  const handleSave = () => {
    const createProject = async () => {
      try {
        setLoading(true)
        const response: AxiosResponse<ProjectResponse> = await ProjectApi.create(newProject)
        const createdProject = response.data.data

        if (createdProject.id) {
          navigate(`/projects/${createdProject.id}`, { replace: true })
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    if (hasTitle) {
      createProject()
    }
  }

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Новый проект')}</PageHeader>

      <FlexLayout.Content scrollable>
        <div className="project-input-wrapper">
          <Input
            className="project-input"
            value={newProject.title}
            header={<RequiredInputLabel label={t('Название')} />}
            name="title"
            onChange={handleChange}
          />
        </div>
        <div className="project-input-wrapper">
          <Input
            className="project-input"
            value={newProject.description}
            header={t('Описание')}
            name="description"
            onChange={handleChange}
          />
        </div>
      </FlexLayout.Content>

      <div className="new-project-footer">
        <Button
          className="create-project-button"
          onClick={handleSave}
          disabled={!hasTitle || loading}
          stretched
        >
          {t('Создать проект')}
        </Button>
      </div>
    </FlexLayout.Container>
  )
}

export default NewProject
