import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { TaskStore } from 'stores'
import { useValueContext, ValueContext } from 'contexts'

export const TaskLayout = () => {
  const { projectId, taskId } = useParams()
  const navigate = useNavigate()
  const projectStore = useValueContext()

  const [store] = useState(() => new TaskStore(projectId || '', taskId || '', navigate))

  useEffect(() => {
    const loadDictsAndInit = async () => {
      await projectStore.initDictsIfEmpty()
      store.init(projectStore.config, projectStore.users)
    }

    loadDictsAndInit()
  }, [projectStore, store])

  return (
    <ValueContext.Provider value={{ taskStore: store, projectStore }}>
      <Outlet />
    </ValueContext.Provider>
  )
}
