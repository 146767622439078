import cn from 'classnames'

import './styles.scss'

interface Props {
  open: boolean
  variant?: 'sm' | 'lg'
  className?: string
}

export const Spinner = ({ open = false, variant = 'lg', className }: Props) =>
  open ? <div className={cn('spinner', className, { [variant]: variant })} /> : null
