import { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { ProjectStore } from 'stores'
import { ValueContext } from 'contexts'

export const ProjectLayout = () => {
  const { projectId } = useParams()

  const [store] = useState(() => new ProjectStore(projectId || ''))

  return (
    <ValueContext.Provider value={store}>
      <Outlet />
    </ValueContext.Provider>
  )
}
