import { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { FlexLayout, PageHeader, OptionCard } from 'components'
import { useTranslateConsts } from 'utils'

import { WeekDays } from './WeekDays'
import './styles.scss'

const selectedDaysId = 'selectedDays'

export const Repeat = () => {
  const { t } = useTranslation()
  const { repeatTimeItems, dayNames } = useTranslateConsts()
  const [option, setOption] = useState(null)

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Повторение')}</PageHeader>
      <FlexLayout.Content scrollable>
        <div className="button-container">
          {repeatTimeItems.map((it: any) => {
            const isSelectedDaysItem = it.id === selectedDaysId

            return (
              <>
                <OptionCard
                  key={it.id}
                  className={cn('button-repeat-type', { active: option === it.id })}
                  onClick={() => setOption(it.id)}
                >
                  {it.title}
                </OptionCard>
                {isSelectedDaysItem && option === selectedDaysId && <WeekDays days={dayNames} />}
              </>
            )
          })}
        </div>
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
}
