import { useTranslation } from 'react-i18next'

import Icon from './message-write-outline.svg?react'

const FeedbackButton = () => {
  const { t } = useTranslation()

  return (
    <button className="feedback-button">
      <Icon />

      <div className="content">{t('Поделиться обратной связью')}</div>
    </button>
  )
}

export default FeedbackButton
