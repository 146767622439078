import React, { memo, useState, useEffect, ReactNode } from 'react'
import cn from 'classnames'

import { ArrowIcon } from 'components'
import { Nullable } from 'types'

import './styles.scss'
import {
  dayNamesRus,
  monthNamesRus,
  isValidDate,
  getValidDate,
  getDaysInMonth,
  getFirstDayOfMonth,
} from './utils'

const getPreviousMonthDays = (month: number, year: number, firstDay: number): ReactNode[] => {
  const days = []

  const prevMonth = month === 0 ? 11 : month - 1
  const prevYear = month === 0 ? year - 1 : year
  const prevMonthDays = getDaysInMonth(prevMonth, prevYear)

  for (let i = 0; i < firstDay; i++) {
    const day = prevMonthDays - firstDay + i + 1
    days.push(
      <div key={`prev-${day}`} className="day empty">
        {day}
      </div>,
    )
  }

  return days
}

interface CurrentMonthDays {
  year: number
  month: number
  daysInMonth: number
  currentDate: Date
  onDateClick: (day: number, month: number, year: number) => void
  disabled?: boolean
}

const getCurrentMonthDays = ({
  year,
  month,
  disabled,
  daysInMonth,
  currentDate,
  onDateClick,
}: CurrentMonthDays): ReactNode[] => {
  const currentDays = []

  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i)
    const isSelected =
      isValidDate(currentDate) && date.toDateString() === currentDate.toDateString()
    const isToday = date.toDateString() === new Date().toDateString()

    currentDays.push(
      <div
        key={`day-${i}`}
        className={cn('day', {
          disabled,
          selected: isSelected,
          today: isToday,
        } as any)}
        onClick={() => onDateClick(i, month, year)}
      >
        {i}
      </div>,
    )
  }

  return currentDays
}

const getNextMonthDays = (daysInMonth: number, firstDay: number): ReactNode[] => {
  const days = []
  const nextMonthDays = 42 - (firstDay + daysInMonth) // 42 - общее кол-во ячеек в гриде календаря

  for (let i = 1; i <= nextMonthDays; i++) {
    days.push(
      <div key={`next-${i}`} className="day empty">
        {i}
      </div>,
    )
  }

  return days
}

const renderCalendar = (
  currentDate: Date,
  onDateClick: (day: number, month: number, year: number) => void,
  disabled?: boolean,
): React.ReactNode[] => {
  const month = currentDate.getMonth()
  const year = currentDate.getFullYear()
  const daysInMonth = getDaysInMonth(month, year)
  const firstDay = getFirstDayOfMonth(month, year)

  const calendarDays = []

  calendarDays.push(...getPreviousMonthDays(month, year, firstDay))
  calendarDays.push(
    ...getCurrentMonthDays({ month, year, daysInMonth, disabled, currentDate, onDateClick }),
  )
  calendarDays.push(...getNextMonthDays(daysInMonth, firstDay))

  return calendarDays
}

interface Props {
  onChange: (d: Date) => void
  value?: Nullable<Date>
  disabled?: boolean
  className?: string
  monthNames?: string[] // Можно передать переведенные на другой язык значения
  dayNames?: string[] // Можно передать переведенные на другой язык значения
}

export const Calendar = memo((props: Props) => {
  const {
    value,
    className,
    monthNames = monthNamesRus,
    dayNames = dayNamesRus,
    disabled,
    onChange,
  } = props
  const initialDate = getValidDate(value)

  const [currentDate, setCurrentDate] = useState<Date>(initialDate)

  useEffect(() => {
    setCurrentDate(getValidDate(value))
  }, [value])

  const onPrevMonth = () => {
    let newMonth = currentDate.getMonth() - 1
    let newYear = currentDate.getFullYear()

    if (newMonth < 0) {
      newMonth = 11
      newYear -= 1
    }

    setCurrentDate(new Date(newYear, newMonth, 1))
  }

  const onNextMonth = () => {
    let newMonth = currentDate.getMonth() + 1
    let newYear = currentDate.getFullYear()

    if (newMonth > 11) {
      newMonth = 0
      newYear += 1
    }

    setCurrentDate(new Date(newYear, newMonth, 1))
  }

  const onDateClick = (day: number, month: number, year: number) => {
    if (disabled) {
      return
    }

    const newDate = new Date(year, month, day)
    onChange(newDate)
  }

  return (
    <div className={cn('calendar', className)}>
      <div className="header">
        <button className="navButton" onClick={onPrevMonth} disabled={disabled}>
          <ArrowIcon />
        </button>
        <div>
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </div>
        <button className="navButton buttonNext" onClick={onNextMonth} disabled={disabled}>
          <ArrowIcon />
        </button>
      </div>
      <div className="days">
        {dayNames.map((day) => (
          <div key={day} className="dayName">
            {day}
          </div>
        ))}
      </div>
      <div className="grid">{renderCalendar(currentDate, onDateClick, disabled)}</div>
    </div>
  )
})
