import { useTranslation } from 'react-i18next'

import { Chip } from 'components'
import type { IProjectItemIssue } from 'types'

const IssueList = ({ items }: { items: IProjectItemIssue[] }) => {
  const { t } = useTranslation()

  return (
    <ul className="issue-list">
      {items.map((issue, index) => (
        <li key={index} className="issue-item">
          <Chip background={issue.colorHex}>{issue.count}</Chip>
          <div className="label">{t(issue.status)}</div>
        </li>
      ))}
    </ul>
  )
}

export default IssueList
