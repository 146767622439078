import { BaseSvgIcon } from './BaseSvgIcon'

export const ArrowIcon = (props: any) => {
  const { width = 18, height = 18, fill = 'currentColor', ...rest } = props

  return (
    <BaseSvgIcon width={width} height={height} fill={fill} viewBox="0 0 24 24" {...rest}>
      <polygon points="15.293 3.293 6.586 12 15.293 20.707 16.707 19.293 9.414 12 16.707 4.707 15.293 3.293" />
    </BaseSvgIcon>
  )
}
