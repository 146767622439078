import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { ProjectsStore } from 'stores'
import { useInfiniteScroll } from 'utils'
import { PageHeader, FlexLayout } from 'components'

import ProjectList from './ProjectList'

const Projects = observer(() => {
  const [store] = useState(() => new ProjectsStore())
  const { t } = useTranslation()
  const { pagination, loadMoreProjects } = store

  useEffect(() => {
    store.loadAll()
  }, [store])

  useEffect(() => {
    if (pagination.currentPage > 1) loadMoreProjects()
  }, [pagination.currentPage, loadMoreProjects])

  const onScrollToBottom = () => pagination.setNextPage()
  const onScroll = useInfiniteScroll(store.loadingMore, onScrollToBottom)

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Проекты')}</PageHeader>

      <FlexLayout.Content onScroll={onScroll} scrollable>
        <ProjectList items={store.items} loading={store.loading} loadingMore={store.loadingMore} />
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
})

export default Projects
