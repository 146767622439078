import { BaseSvgIcon } from './BaseSvgIcon'

export const GearIcon = (props: any) => {
  const { width = 24, height = 24, fill = 'var(--dark-primary)', ...rest } = props

  return (
    <BaseSvgIcon
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 24 24"
      fillRule="evenodd"
      {...rest}
    >
      <path d="m14.253 1.787c-.116-.373-.438-.645-.826-.695-.941-.123-1.893-.123-2.833 0-.388.05-.711.322-.827.695l-.374 1.207c-.398.115-.788.257-1.167.424 0 0-1.062-.683-1.062-.683-.329-.212-.751-.213-1.08-.002-.799.511-1.529 1.123-2.171 1.821-.264.288-.337.704-.186 1.064l.489 1.165c-.23.344-.438.704-.621 1.076 0-.001-1.253.159-1.253.159-.388.049-.711.32-.828.693-.284.905-.449 1.843-.492 2.79-.018.39.193.756.541.934l1.123.579c.045.412.117.82.216 1.223 0-.001-.858.927-.858.927-.265.287-.339.702-.189 1.063.364.876.841 1.701 1.417 2.454.237.311.634.454 1.015.368l1.232-.279c.299.287.617.553.952.798-.001 0-.062 1.262-.062 1.262-.018.39.192.756.539.936.842.436 1.737.762 2.663.969.381.085.777-.059 1.014-.371l.765-1.006c.413.028.828.028 1.241 0 0 0 .765 1.006.765 1.006.236.312.632.456 1.014.371.925-.207 1.82-.533 2.662-.969.348-.18.558-.546.539-.936l-.061-1.262c.334-.245.652-.511.952-.798-.001 0 1.232.279 1.232.279.381.086.777-.057 1.015-.368.576-.753 1.052-1.578 1.417-2.454.15-.361.076-.776-.189-1.063l-.858-.928c.099-.402.171-.811.215-1.223 0 .001 1.124-.578 1.124-.578.347-.178.559-.544.541-.934-.043-.947-.208-1.885-.492-2.79-.117-.373-.44-.644-.828-.693l-1.254-.16c-.183-.371-.39-.731-.621-1.075.001 0 .489-1.165.489-1.165.152-.36.079-.776-.186-1.064-.642-.698-1.371-1.31-2.17-1.821-.33-.211-.751-.21-1.08.002l-1.063.683c-.379-.167-.769-.309-1.167-.425.001.001-.374-1.206-.374-1.206zm-2.243 4.367c-3.226 0-5.846 2.619-5.846 5.846s2.62 5.846 5.846 5.846c3.227 0 5.847-2.619 5.847-5.846s-2.62-5.846-5.847-5.846zm0 2c2.123 0 3.847 1.723 3.847 3.846s-1.724 3.846-3.847 3.846-3.846-1.723-3.846-3.846 1.723-3.846 3.846-3.846z" />
    </BaseSvgIcon>
  )
}
