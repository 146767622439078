import { BaseSvgIcon } from './BaseSvgIcon'

export const SearchIcon = (props: any) => {
  const { width = 18, height = 18, fill = 'currentColor', ...rest } = props

  return (
    <BaseSvgIcon width={width} height={height} fill="none" viewBox="0 0 24 24" {...rest}>
      <path
        d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </BaseSvgIcon>
  )
}
