import { Checkbox } from '@telegram-apps/telegram-ui'

import { ScrollableList } from './ScrollableList'
import './styles.scss'

interface Props {
  options: Record<string, any>[]
  selected: number[]
  onSelect: (value: number) => void
  onClear: () => void
}

export const StatusOptions = ({ options, selected, onSelect, onClear }: Props) => {
  return (
    <ScrollableList onClear={onClear}>
      {options?.map((it) => {
        const backgroundColor = it.colorHex

        return (
          <div key={it.id} className="status-label" onClick={() => onSelect(it.id)}>
            <div className="d-flex align-items-center">
              <span className="status-badge" style={{ backgroundColor }} />
              <span className="status-name">{it.title}</span>
            </div>

            <Checkbox
              className="filter-list-checkbox"
              checked={selected.includes(it.id)}
              onChange={() => {}}
            />
          </div>
        )
      })}
    </ScrollableList>
  )
}
