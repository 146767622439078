import { IEditedTask } from 'types'

type EmptyTaskParams = {
  type?: Record<string, any>
  status?: Record<string, any>
  priority?: Record<string, any>
  owner?: Record<string, any>
}

export const createEmptyTask = (params?: EmptyTaskParams): Record<string, any> => {
  const { type, status, priority, owner } = params ?? {}

  return {
    title: '',
    description: '',
    type,
    status,
    priority,
    owner,
    assignedTo: null,
    tags: [],
  }
}

export const serializeTask = (task: any): IEditedTask => {
  if (!task) return {} as IEditedTask

  const { id, title, description, type, status, priority, assignedTo, owner, tags } = task

  return {
    id,
    title,
    description,
    typeId: type?.id,
    priorityId: priority?.id,
    statusId: status?.id,
    assignedToId: assignedTo?.id,
    reporterToId: owner?.id,
    tags,
  }
}
