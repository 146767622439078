import React from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Input } from '@telegram-apps/telegram-ui'
import Editor, { ContentEditableEvent } from 'react-simple-wysiwyg'

import { useValueContext } from 'contexts'
import { Select, StatusSelect } from 'components'

import { AttachedFiles } from './components'

const Form = observer(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { taskStore } = useValueContext()
  const { task, setTaskField } = taskStore

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskField('title', e.target.value)
  }

  const handleDescriptionChange = (e: ContentEditableEvent) => {
    setTaskField('description', e.target.value)
  }

  return (
    <form>
      <div className="task-input-wrapper">
        <Input
          value={task?.title || ''}
          placeholder={`${t('Заголовок задачи')} *`}
          className="task-input"
          onChange={handleTitleChange}
        />
      </div>
      <div className="editor-wrapper">
        <Editor value={task?.description} onChange={handleDescriptionChange} />
      </div>
      <StatusSelect
        value={t(task?.status?.title)}
        color={task?.status?.colorHex}
        onClick={() => navigate('settings/status')}
      />
      <Select
        label={t('Напоминание (повторяющаяся задача)')}
        value="Никогда"
        onClick={() => navigate('settings/reminder')}
      />
      <Select
        label={t('Исполнитель')}
        value={task?.assignedTo?.name}
        onClick={() => navigate('settings/assignee')}
      />
      <Select
        label={t('Автор')}
        value={task?.owner?.name}
        onClick={() => navigate('settings/reporter')}
      />
      <Select
        label={`${t('Приоритет')} *`}
        value={t(task?.priority?.title)}
        onClick={() => navigate('settings/priority')}
      />

      <div className="select-option">
        <span className="select-label">{t('Прикрепленные файлы')}</span>
        <AttachedFiles />
      </div>
    </form>
  )
})

export default Form
