import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { AssigneeLabel, FlexLayout, OptionCard, PageHeader } from 'components'
import { useValueContext } from 'contexts'
import { ITaskPerson } from 'types'

import './styles.scss'

export const Reporter = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { taskStore, projectStore } = useValueContext()
  const { task, setTaskField } = taskStore
  const { users } = projectStore

  const onSelect = (author: ITaskPerson) => {
    setTaskField('owner', author)
    navigate(-1)
  }

  return (
    <FlexLayout.Container vertical>
      <PageHeader>{t('Автор')}</PageHeader>
      <FlexLayout.Content scrollable>
        {users.map((x: ITaskPerson) => {
          return (
            <OptionCard
              key={x.id}
              className={cn('assignee-option', { selected: x.id === task?.owner?.id })}
              onClick={() => onSelect(x)}
            >
              <AssigneeLabel data={x} />
            </OptionCard>
          )
        })}
      </FlexLayout.Content>
    </FlexLayout.Container>
  )
})
