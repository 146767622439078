import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { NoData, PageSpinner, Spinner } from 'components'
import type { IProjectIssue } from 'types'

import TaskItem from './TaskItem'
import './styles.scss'

interface TaskListProps {
  items: IProjectIssue[]
  loading: boolean
  loadingMore: boolean
}

const TaskList = ({ items = [], loading = false, loadingMore = false }: TaskListProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleItemClick = useCallback(
    (issueId: number) => {
      if (issueId) navigate(`tasks/${issueId}`)
    },
    [navigate],
  )

  return (
    <>
      <PageSpinner open={loading} />
      <Spinner className="list-spinner" variant="sm" open={loadingMore} />
      {items.length ? (
        <ul className="task-list">
          {items.map((issue, index) => (
            <TaskItem key={issue.id} number={index + 1} issue={issue} onClick={handleItemClick} />
          ))}
        </ul>
      ) : (
        <NoData>{t('Пока что у вас нет задач')}</NoData>
      )}
    </>
  )
}

export default TaskList
