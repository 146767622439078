import cn from 'classnames'

import './styles.scss'

interface BottomFooterProps extends React.HTMLAttributes<HTMLElement> {
  justify?: 'start' | 'center' | 'end' | 'around' | 'between' | 'evenly'
}

const BottomFooter = ({ className, justify = 'center', ...rest }: BottomFooterProps) => {
  return <footer className={cn('bottom-footer', `bottom-footer-${justify}`, className)} {...rest} />
}

export default BottomFooter
