import { makeAutoObservable } from 'mobx'

import { SortDirection } from 'types'

import type { ISorting, SortingOptions } from './types'

const getSortString = (sortKey: string, isDesc: boolean) => {
  if (sortKey === '') return undefined

  return isDesc ? `-${sortKey}` : sortKey
}

export default class SortingStore {
  key: string
  direction: SortDirection

  onSortKeyChange
  onSortDirectionChange

  constructor(options?: SortingOptions) {
    this.key = ''
    this.direction = SortDirection.DESC

    this.onSortKeyChange = options?.onSortKeyChange
    this.onSortDirectionChange = options?.onSortDirectionChange

    makeAutoObservable(this, undefined, { autoBind: true })
  }

  // Сортировка по возрастанию
  get isAsc() {
    return this.direction === SortDirection.ASC
  }

  get isDesc() {
    return this.direction === SortDirection.DESC
  }

  setKey(sortBy: string) {
    if (sortBy !== this.key) {
      this.key = sortBy
      this.keyChanged()
    }
  }

  resetKey() {
    this.setKey('')
  }

  setDirection(direction: SortDirection) {
    if (direction !== this.direction) {
      this.direction = direction
      this.directionChanged()
    }
  }

  setAsc() {
    this.setDirection(SortDirection.ASC)
  }

  setDesc() {
    this.setDirection(SortDirection.DESC)
  }

  resetDirection() {
    this.setDesc()
  }

  getSort() {
    return getSortString(this.key, this.isDesc)
  }

  setSort(sorting: ISorting) {
    if (sorting) {
      this.setKey(sorting.key)
      this.setDirection(sorting.direction)
    }
  }

  resetSort() {
    this.resetKey()
    this.resetDirection()
  }

  keyChanged() {
    if (typeof this.onSortKeyChange === 'function') {
      this.onSortKeyChange(this.key)
    }
  }

  directionChanged() {
    if (typeof this.onSortDirectionChange === 'function') {
      this.onSortDirectionChange(this.direction)
    }
  }
}
